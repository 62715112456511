import React, {useState, useEffect} from 'react'
import axios from 'axios'
let carData=[];

const req = async () => {
  const response = await axios.get('/cardata').then(data=>{
    data.data.forEach(element => {
      carData.push(element)
    });
  })
  console.log(response);
}
req()

export default carData;
