import React from 'react'

const settings = {
  "cname":" Elite Auto Sales",
  "email":" info@eliteautosales.com",
  "phone":" 987654321",
  "location": " New York City, USA",
  "about": " We specialize in luxury and high-end vehicles, offering a wide range of options for discerning customers. Our team of experts is on hand to assist with your purchase and provide personalized service.",
  "serviceone": " Financing options",
  "servicetwo": " Trade-ins",
  "servicethree": " Customization services",
  "servicefour": " Extended warranties",
  "committment": "At Elite Auto Sales, we are committed to providing our customers with the highest level of service and satisfaction. We believe in honesty and transparency in all of our business practices and strive to make the car buying process as smooth and stress-free as possible."
}

export default settings
