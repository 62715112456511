import React, { useState,useEffect } from "react";
import { Col } from "reactstrap";
import "../../styles/services-list.css";
import {Link, NavLink} from 'react-router-dom'
import axios from 'axios'

const ServicesList = () => {
  const[servicesData,setsd]=useState([]);

  async function req() {
    const response = await axios.get('/allbrands').then(data=>{
        setsd(data.data) 
    })
    console.log(response);
  }
  useEffect(()=>{
    req()
  },[])
 

  return (
    <>
      {servicesData.map((item) => (
        <ServiceItem item={item} key={item.id} />
      ))
      }
    </>
  );
};

const ServiceItem = ({ item,index }) => (
  <Col lg="1" md="2" sm="2" xs="2" xl="2" className="mb-3">
    <NavLink
          to={`/cars/${item.car_brand}`}                    
          key={index} 
    >
            <div class="card">
              <div class="card-img-top d-flex align-items-center">
                <img src={`https://www.carlogos.org/car-logos/${item.car_brand}-logo.png`} style={{
                  margin: 'auto auto',
                  width: '100%'
                }} alt="My Image" />
              </div>
            </div>
    </NavLink>
  </Col>
);
/*

                    {item.display}
                  </NavLink>
*/
export default ServicesList;
