import React, { useRef, useState } from "react";
import { Link,NavLink } from "react-router-dom";
import "../../styles/find-car-form.css";
import "../../styles/find-car-form.css";
import { Form, FormGroup } from "reactstrap";
import { useFormik } from "formik";
import axios from 'axios'
import { useNavigate } from "react-router-dom";

const FindCarForm = () => {
  const Navigate = useNavigate();
          const Formik = useFormik({
            initialValues: {
              min: "",
              max: "",
              brand: "",
              ac: ""
            },
            onSubmit: (values)=>{
              axios.post(`/findcar`,values).then(response => {
                console.log(response);
                //var id = response[0].id;
                Navigate(`/cars/${response.data.brand}`)
              });
            }
          });
 
  return (
    <Form className="form" onSubmit={Formik.handleSubmit}>
      <div className=" d-flex align-items-center justify-content-between flex-wrap">
        <FormGroup className="form__group">
          <input type="number" name="min" onChange={Formik.handleChange} min="0" val="1" placeholder="Min price" />
        </FormGroup>

        <FormGroup className="form__group">
          <input type="number" name="max" onChange={Formik.handleChange} min="0" placeholder="Max price" />
        </FormGroup>

        <FormGroup className="form__group"> 
          <input type="text" name="brand" onChange={Formik.handleChange} placeholder="Brand e.g Tesla" />
        </FormGroup>

        <FormGroup className="select__group">
          <select name="ac" onChange={Formik.handleChange}>
            <option value="automatic">Automatic car</option>
            <option value="manual">Manual car</option>
          </select>
        </FormGroup>

        <FormGroup className="form__group">
 
            <button className="btn find__car-btn">Find Car</button>

        </FormGroup>
      </div>
    </Form>
  );
};

export default FindCarForm;
